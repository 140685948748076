import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, Injectable, isDevMode, LOCALE_ID, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NotFoundComponent} from './not-found/not-found.component';
import {GraphQLModule} from './graphql/graphql.module';
import {HttpClientModule} from '@angular/common/http';
import {NgxsModule} from '@ngxs/store';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {registerLocaleData} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import {GoogleTagManagerModule} from 'angular-google-tag-manager';
import {MatDialogModule} from '@angular/material/dialog';
import * as Sentry from '@sentry/angular';
import {Router} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {UpdateModule} from './update/update.module';
import {AppRequiredModule} from './app-required/app-required.module';
import {ConnectionModule} from './connection/connection.module';
import {ERROR_HANDLERS, ErrorHandlerManager} from './error-handler-manager';
import {provideLumberjack} from '@ngworker/lumberjack';
import {provideLumberjackConsoleDriver} from '@ngworker/lumberjack/console-driver';
import {NgxsStoragePluginModule, SESSION_STORAGE_ENGINE} from '@ngxs/storage-plugin';
import {SettingsState} from './host/settings/settings.state';

registerLocaleData(localeRu);

if (environment.sentryUrl) {
  Sentry.init({
    dsn: environment.sentryUrl,
    environment: environment.sentryEnv,
    normalizeDepth: 10,
    tracesSampleRate: environment.sentrySampleRate,
    tracePropagationTargets: [environment.apiUrl],
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.breadcrumbsIntegration({
        console: true,
        xhr: false,
        fetch: false,
      }),
    ],
  });
}

@Injectable()
export class ChunkErrorRefreshHandler implements ErrorHandler {
  handleError(error: any): void {
    if (/Loading chunk [\d]+ failed/.test(error?.message)) {
      window.location.reload();
    }
  }
}

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRequiredModule,
    AppRoutingModule,
    HttpClientModule,
    NgxsModule.forRoot([], {
      developmentMode: !environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      keys: [
        SettingsState,
        {
          key: 'host.resultsType',
          engine: SESSION_STORAGE_ENGINE,
        }
      ],
    }),
    GraphQLModule,
    GoogleTagManagerModule.forRoot({
      id: environment.gtmId,
    }),
    MatDialogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerImmediately'
    }),
    UpdateModule,
    ConnectionModule,
  ],
  providers: [
    provideLumberjack(),
    provideLumberjackConsoleDriver(),
    {
      provide: 'googleTagManagerMode',
      useValue: 'silent'
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: ERROR_HANDLERS,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
      multi: true,
    },
    {
      provide: ERROR_HANDLERS,
      useClass: ChunkErrorRefreshHandler,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useExisting: ErrorHandlerManager,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: LOCALE_ID,
      useValue: 'ru',
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
